<template>
  <ElDialog
    id="condition-report-completed-recently-dialog"
    :center="true"
    top="30vh"
    :show-close="false"
    :fullscreen="$viewport.lt.md"
    :visible.sync="isVisible"
    width="400px"
    @close="$emit('close')"
  >
    <div class="row d-flex justify-content-center">
      <p class="mt-1 mb-0 text-center font-15">
        A {{ uiFlags.conditionType }} has been completed recently ({{ uiFlags.completedDate }}). You can see the report [<a
          :href="UrlFormatter(uiFlags.docLink)"
          target="_blank"
        >here</a>].
        Are you sure you want to proceed with ordering another report for this unit?
      </p>
    </div>
    <div slot="footer">
      <el-button
        size="medium"
        @click="close"
      >
        Cancel
      </el-button>
      <el-button
        type="primary"
        size="medium"
        :loading="uiFlags.isLoading"
        @click="proceed"
      >
        Proceed
      </el-button>
    </div>
  </ElDialog>
</template>

<script>
import { reactive, ref } from '@vue/composition-api';
import UrlFormatter from '../../utils/UrlFormatterUtil';

export default {
  name: 'ConditionReportInspectionCompletedRecentlyDialog',
  setup(props, context) {
    const uiFlags = reactive({
      isLoading: false,
      conditionType: '',
      completedDate: '',
      docLink: '',
      redirect: '',
    });
    const isVisible = ref(false);
    return {
      open,
      close,
      proceed,
      isVisible,
      uiFlags,
      UrlFormatter,
    };
    function open(params, redirectPage) {
      uiFlags.conditionType = params.type;
      uiFlags.completedDate = params.date;
      uiFlags.docLink = params.link;
      uiFlags.redirect = redirectPage;
      isVisible.value = true;
    }
    function close() {
      isVisible.value = false;
    }
    function proceed() {
      if (uiFlags.redirect) {
        context.root.$router.push(uiFlags.redirect);
      }
    }
  },
};
</script>
