<template>
  <SdPage>
    <div
      class="col-12 pt-0"
    >
      <ConditionReportType
        :property="propertyRef"
        @update-payload="processSelection"
      />
    </div>
    <ConditionReportInspectionCompletedRecentlyDialog
      ref="inspectionCompletedRecentlyDialogRef"
    />
  </SdPage>
</template>

<script>
import { ref } from '@vue/composition-api';
import ConditionReportType from '@/components/condition-report/ConditionReportType';
import ConditionReportInspectionCompletedRecentlyDialog from '@/components/condition-report/ConditionReportInspectionCompletedRecentlyDialog';
import { showErrorNotify } from '@/utils/NotifyUtil';
import { ConditionReport } from '../../../constants/ConditionReport';

export default {
  name: 'ConditionReportAdd',
  components: {
    ConditionReportType,
    ConditionReportInspectionCompletedRecentlyDialog,
  },
  setup(props, context) {
    const propertyRef = ref({});
    const inspectionCompletedRecentlyDialogRef = ref(null);
    return {
      propertyRef,
      processSelection,
      inspectionCompletedRecentlyDialogRef,
    };

    async function processSelection(componentFields) {
      if (!componentFields.value.selectedUnit) {
        return;
      }
      const unitId = componentFields.value.selectedUnit.id;
      const redirectPage = {
        name: null,
        params: {
          id: componentFields.value.selectedUnit.property_id,
          unit_id: unitId,
        },
      };
      if (!componentFields.value.choosePressed || !componentFields.value.selectedUnit) {
        return;
      }
      const selectedPlan = componentFields.value.selectedPlan;
      if (selectedPlan) {
        if (selectedPlan === ConditionReport.REPORT_TYPE_IN_OUT) {
          redirectPage.name = 'app.condition-report.move_in_out';
        } else if (selectedPlan === ConditionReport.REPORT_TYPE_PERIODIC) {
          redirectPage.name = 'app.condition-report.periodic';
        } else if (selectedPlan === ConditionReport.REPORT_TYPE_VACANCY_CHECK) {
          redirectPage.name = 'app.condition-report.vacancy_check';
        }
        const canProceeed = await checkForDuplicate(unitId, selectedPlan);
        if (canProceeed) {
          checkForCompletedRecently(unitId, selectedPlan, redirectPage);
        }
      }
      componentFields.value.choosePressed = false;
    }
    async function checkForCompletedRecently(unitId, plan, redirectPage) {
      try {
        const result = await context.root.$store.dispatch('ConditionReport/checkForCompletedRecently', {
          unit_id: unitId,
          type: plan,
        });
        if (Object.keys(result).length === 0) {
          context.root.$router.push(redirectPage);
        } else {
          inspectionCompletedRecentlyDialogRef.value.open(result, redirectPage);
        }
      } catch (error) {
        showErrorNotify(context, error.message, { duration: 0 });
      }
    }
    async function checkForDuplicate(unitId, plan) {
      try {
        await context.root.$store.dispatch('ConditionReport/checkForDuplicate', {
          unit_id: unitId,
          type: plan,
        });
        return true;
      } catch (error) {
        showErrorNotify(context, error.message, { duration: 0 });
        return false;
      }
    }
  },
};

</script>
