<template>
  <el-alert
    type="error"
    show-icon
    :closable="false"
  >
    {{ customMessage }}
  </el-alert>
</template>

<script>
export default {
  name: 'ConditionReportUnitAssignedAlert',
  props: {
    customMessage: {
      type: String,
      required: false,
      default: '',
    },
  },
};
</script>
